// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kancelaria-tsx": () => import("./../../../src/pages/kancelaria.tsx" /* webpackChunkName: "component---src-pages-kancelaria-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-partnerzy-tsx": () => import("./../../../src/pages/partnerzy.tsx" /* webpackChunkName: "component---src-pages-partnerzy-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-specjalizacje-e-commerce-tsx": () => import("./../../../src/pages/specjalizacje/e-commerce.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-e-commerce-tsx" */),
  "component---src-pages-specjalizacje-fuzje-i-przejecia-tsx": () => import("./../../../src/pages/specjalizacje/fuzje-i-przejecia.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-fuzje-i-przejecia-tsx" */),
  "component---src-pages-specjalizacje-ipo-i-corporate-governance-tsx": () => import("./../../../src/pages/specjalizacje/ipo-i-corporate-governance.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-ipo-i-corporate-governance-tsx" */),
  "component---src-pages-specjalizacje-it-i-media-tsx": () => import("./../../../src/pages/specjalizacje/it-i-media.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-it-i-media-tsx" */),
  "component---src-pages-specjalizacje-kontrakty-cywilne-i-handlowe-tsx": () => import("./../../../src/pages/specjalizacje/kontrakty-cywilne-i-handlowe.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-kontrakty-cywilne-i-handlowe-tsx" */),
  "component---src-pages-specjalizacje-ochrona-danych-osobowych-tsx": () => import("./../../../src/pages/specjalizacje/ochrona-danych-osobowych.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-ochrona-danych-osobowych-tsx" */),
  "component---src-pages-specjalizacje-prawo-konkurencji-tsx": () => import("./../../../src/pages/specjalizacje/prawo-konkurencji.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-prawo-konkurencji-tsx" */),
  "component---src-pages-specjalizacje-prawo-konsumenckie-tsx": () => import("./../../../src/pages/specjalizacje/prawo-konsumenckie.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-prawo-konsumenckie-tsx" */),
  "component---src-pages-specjalizacje-spolki-i-prawo-korporacyjne-tsx": () => import("./../../../src/pages/specjalizacje/spolki-i-prawo-korporacyjne.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-spolki-i-prawo-korporacyjne-tsx" */),
  "component---src-pages-specjalizacje-telekomunikacja-tsx": () => import("./../../../src/pages/specjalizacje/telekomunikacja.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-telekomunikacja-tsx" */),
  "component---src-pages-specjalizacje-tsx": () => import("./../../../src/pages/specjalizacje.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-tsx" */),
  "component---src-pages-w-budowie-tsx": () => import("./../../../src/pages/w-budowie.tsx" /* webpackChunkName: "component---src-pages-w-budowie-tsx" */)
}

